<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">注册</h1>
				<p hidden class="text-lg">Use these awesome forms to login or create new account in your project for free.</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->

		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
			<template #title>
				<h5 class="font-semibold text-center">Register With</h5>
			</template>
			<div class="sign-up-gateways" hidden>
				<a-button>
					<img src="images/logos/logos-facebook.svg" alt="">
				</a-button>
				<a-button>
					<img src="images/logos/logo-apple.svg" alt="">
				</a-button>
				<a-button>
					<img src="images/logos/Google__G__Logo.svg.png" alt="">
				</a-button>
			</div>
			<p class="text-center my-25 font-semibold text-muted">Or</p>
			<a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit">
				<a-form-item class="mb-10">
					<a-input v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
					]" placeholder="输入你的邮箱">
					</a-input>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your Password!' }] },
					]" type="password" placeholder="输入你的密码">
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-checkbox v-decorator="[
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						},
					]">
						I agree the <a href="#" class="font-bold text-dark">Terms and Conditions</a>
					</a-checkbox>
				</a-form-item>
				<a-form-item>
					<a-button :loading="loading" type="primary" block html-type="submit" class="login-form-button">
						注册
					</a-button>
				</a-form-item>
			</a-form>
			<p class="font-semibold text-muted text-center">Already have an account? <router-link to="/sign-in"
					class="font-bold text-dark">登录</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>

export default ({
	data() {
		return {
			loading: false,
		}
	},
	beforeCreate() {
		// Creates the form and adds to it component's "form" property.
		this.form = this.$form.createForm(this, { name: 'normal_login' });
	},
	methods: {
		// Handles input validation after submission.
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					this.$axios.post('/api/platform/user/register', values)
						.then(res => {
							if (res.code === 0) {
								this.$message.info(res.msg)
								this.loading = false
							} else {
								this.$message.error(res.msg)
								this.loading = false
							}
						})
				}
			});
		},
	},
})

</script>

<style lang="scss"></style>